.modal-title {
	margin-top: gridUnit(2);
	
	font-weight: 900;
	font-size: pxToRem(35);
	letter-spacing: .4/35 * 1em;
	color: #404040;
}

.modal-body {
	margin-top: gridUnit(2);
	
	font-weight: 300;
	font-size: pxToRem(16);
	line-height: 1.7;
	color: $darkGrey;
	
	p {
		margin-top: 0;
		margin-bottom: 0;
		
		+ p {
			margin-top: gridUnit(2);
		}
	}
}

.modal-body-loader {
	display: inline-block;
	width: pxToRem(32);
	margin-top: pxToRem(12);
}