.user_settings-scrollable_content {
	height: auto;
}

.user_settings-content {
	margin-top: gridUnit(-3);
	padding: 0 gridUnit(3) gridUnit(3) gridUnit(3);
}

.user_settings-header {
	background-color: $colorDarkGrey;
}

.user_settings-title {
	@include deco_typo_heading_3-bold;

	padding: pxToRem(50) 0 pxToRem(75);

	color: #fff;
	text-align: center;
}

.user_settings-profile_picture {
	position: relative;
	padding: gridUnit(1) 0;

	text-align: center;

	&-media_container {
		display: inline-block;
		position: relative;

		&:after {
			content: "";

			position: absolute;
			top: pxToRem(-16);
			left: pxToRem(-16);
			width: calc(100% + 32px);
			height: calc(100% + 32px);

			background-color: #fff;
			border-radius: 50%;
			z-index: 0;
		}
	}

	&-media {
		display: block;
		position: relative;

		border-radius: 50%;

		z-index: 1;
	}

	&-link {
		@include deco_typo_body_small;
		@include deco_anim_underline;

		position: absolute;

		top: pxToRem(70);
		left: calc(50% + 54px);

		color: $mediumGrey;
	}
}

.user_settings-close {
	position: absolute;

	top: pxToRem(16);
	right: pxToRem(16);
	padding: pxToRem(6) pxToRem(6) pxToRem(5) pxToRem(6);
}

.user_settings-close-text {
	@include hidden-visually;
}

.user_settings-close-icon {
	display: inline-block;
	width: pxToRem(18);
	height: pxToRem(18);

	path {
		stroke: rgba(#fff, .6);
		
		transition: $defaultTransition;
		
		.user_settings-close:hover & {
			stroke: #fff;
		}
	}
}

@media only screen and (max-width: $panelWidth) {
	.user_settings-profile_picture-link {
		position: static;
		display: block;
		margin: pxToRem(15) auto;
	}
}