.header_user_profile {
	position: relative;
}

.header_user_profile-button {
	@include deco_typo_body_small;

	position: relative;

	padding: 0 25px 0 28px;

	color: #fff;

	&:after {
		content: "";

		position: absolute;
		right: 2px;
		top: 4px;
		bottom: 0;
		margin: auto;
		width: 6px;
		height: 10px;

		background: url(../images/icons/arrow--white.svg) no-repeat center center;
		background-size: 100%;

		transform: rotate(90deg) translateZ(0);
	}
}

.header_user_profile-button-avatar {
	content: "";

	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	width: 16px;
	height: 17px;

	background-size: 100%;
	border-radius: 100%;
}

// User profile modal
.header_user_profile-modal {
	position: fixed;
	overflow: hidden;
	right: pxToRem(156);
	top: 0;
	width: pxToRem(248);

	background: #fff;
	color: #fff;
	box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.45);

	transform: translateY(-120%);
	z-index: $zIndexModal;

	transition: $defaultTransition;
	&--active {
		transform: translateY(0);
	}
}

.header_user_profile-modal-header {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 20px 40px;

	text-align: center;

	background-color: $colorDarkGrey;


}

.header_user_profile-modal-picture {
	display: block;
	max-width: pxToRem(40);
	max-height: pxToRem(40);
	margin-bottom: pxToRem(13);

	border-radius: 100%;
}

.header_user_profile-modal-title {
	@include deco_typo_heading_5;

	margin-bottom: -4px;
}

.header_user_profile-modal-email {
	@include deco_typo_body_small;
}

.header_user_profile-modal-buttons {
	display: flex;
	flex-direction: column;

	max-width: gridUnit(10.5);
	margin: pxToRem(24) auto;
}

.header_user_profile-modal-button {
	color: $mediumGrey;
	background-color: transparent;



	&:not(:last-child) {
		margin-bottom: 8px;
	}

	&:not(:hover):not(:active):after {
		border: 2px solid $lightGrey;
		opacity: 1;
	}

	&:active:after {
		border-width: 3px;
	}

	&:not(:disabled):hover {
		color: #fff;

		transition: $defaultTransition;
	}

	.icon + .btn-icon-text {
		margin-left: gridUnit(0.5);

		font-weight: 500;
	}

	.icon {
		max-width: 22px;
		max-height: 22px;
	}
}

.header_user_profile-modal-close {
	position: absolute;
	top: 12px;
	right: 4px;
	transform: rotate(-90deg);
	padding: 13px;
	color: #fff;
}
