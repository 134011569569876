.btn {
	@include deco_buttons_base;
}

.btn--plain {
	@include deco_buttons_plain;
}

.btn--plain_secondary {
	@include deco_buttons_plain;
	@include deco_buttons_plain_secondary;
}

.btn--neutral {
	@include deco_buttons_neutral;
}

.btn--neutral_secondary {
	@include deco_buttons_neutral;
	@include deco_buttons_neutral_secondary;
}

.btn--facebook {
	@include deco_buttons_plain;

	background-color: #3b5998;
	width: 100%;

	fill: #fff;

	&:before {
		background-image: linear-gradient(to left, #3b5998, #4a6caa);
	}

	.icon {
		height: pxToRem(20);
	}
}

.btn--googleplus {
	@include deco_buttons_plain;

	background-color: #dc4e41;
	width: 100%;

	fill: #fff;

	&:before {
		background-image: linear-gradient(to left, #dc4e41, #c7493f);
	}

	.icon {
		height: pxToRem(20);
	}
}

.btn-icon {
	display: inline-block;
	width: 100%;

	padding: pxToRem(15) pxToRem(16.5);

	.icon {
		display: inline-block;
		vertical-align: middle;
		width: pxToRem(20);
	}

	.btn-icon-text + .icon {
		margin-left: gridUnit(1);
	}

	.icon + .btn-icon-text {
		margin-left: gridUnit(1);
	}
}

.btn--small {
	@include deco_buttons_small;
}

.btn--vsmall {
	@include deco_buttons_vsmall;
}

@media only screen and (max-width: $panelWidth) {
	.btn-icon {
		.btn-icon-text + .icon {
			margin-left: gridUnit(.5);
		}

		.icon + .btn-icon-text {
			margin-left: gridUnit(.5);
		}
	}
}
