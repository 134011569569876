/*
* HEADINGS
*/
@mixin deco_typo_heading_1--large {
	font-weight: 900;
	font-size: pxToRem(60);
	letter-spacing: pxToRem(0.6);
	line-height: 1;
}

@mixin deco_typo_heading_1 {
	font-weight: 900;
	font-size: pxToRem(40);
	letter-spacing: pxToRem(0.4);
}

@mixin deco_typo_heading_2 {
	font-weight: 500;
	font-size: pxToRem(35);
	letter-spacing: pxToRem(0.4);
}

@mixin deco_typo_heading_2-bold {
	font-weight: 900;
	font-size: pxToRem(35);
	letter-spacing: pxToRem(0.4);
}

@mixin deco_typo_heading_3 {
	font-weight: 500;
	font-size: pxToRem(24);
	letter-spacing: pxToRem(0.4);
}

@mixin deco_typo_heading_3-bold {
	font-weight: 900;
	font-size: pxToRem(24);
	letter-spacing: pxToRem(0.4);
}

@mixin deco_typo_heading_4 {
	font-weight: 500;
	font-size: pxToRem(20);
	letter-spacing: pxToRem(0.4);
}

@mixin deco_typo_heading_4-bold {
	font-weight: 900;
	font-size: pxToRem(20);
	letter-spacing: pxToRem(0.4);
}

@mixin deco_typo_heading_5 {
	font-weight: 900;
	font-size: pxToRem(18);
	letter-spacing: pxToRem(0.4);
}

@mixin deco_typo_heading_6 {
	font-weight: 500;
	font-size: pxToRem(15);
	letter-spacing: pxToRem(0.4);
}

@mixin deco_typo_heading_6-bold {
	font-weight: 900;
	font-size: pxToRem(15);
	letter-spacing: pxToRem(0.4);
}

@mixin deco_typo_body_large {
	font-weight: 300;
	font-size: pxToRem(18);
	line-height: 1.7;
}

@mixin deco_typo_body_regular {
	font-weight: 300;
	font-size: pxToRem(16);
	line-height: 1.7;
}

@mixin deco_typo_body_small_bold {
	font-weight: 500;
	font-size: pxToRem(15);
	line-height: 1.7;
	letter-spacing: pxToRem(0.4);
}

@mixin deco_typo_body_small {
	font-weight: 300;
	font-size: pxToRem(14);
	line-height: 1.7;
	letter-spacing: pxToRem(0.4);
}

@mixin deco_typo_body_vsmall {
	font-size: pxToRem(12);
	line-height: 1.7;
	letter-spacing: pxToRem(0.4);
}
