@-webkit-keyframes ngdialog-fadeout {
	0% {
		opacity: 1;
	}
	
	100% {
		opacity: 0;
	}
}

@keyframes ngdialog-fadeout {
	0% {
		opacity: 1;
	}
	
	100% {
		opacity: 0;
	}
}

@-webkit-keyframes ngdialog-fadein {
	0% {
		opacity: 0;
	}
	
	100% {
		opacity: 1;
	}
}

@keyframes ngdialog-fadein {
	0% {
		opacity: 0;
	}
	
	100% {
		opacity: 1;
	}
}

.ngdialog {
	box-sizing: border-box;
}

.ngdialog *,
.ngdialog *:before,
.ngdialog *:after {
	box-sizing: inherit;
}

.ngdialog {
	position: fixed;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	z-index: 10000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.ngdialog.ngdialog-disabled-animation,
.ngdialog.ngdialog-disabled-animation .ngdialog-overlay,
.ngdialog.ngdialog-disabled-animation .ngdialog-content {
	-webkit-animation: none!important;
	animation: none!important;
}

.ngdialog-overlay {
	position: fixed;
	background: rgba($colorDarkGrey, .8);
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	-webkit-backface-visibility: hidden;
	-webkit-animation: ngdialog-fadein 0.5s;
	animation: ngdialog-fadein 0.5s;
}

.ngdialog-no-overlay {
	pointer-events: none;
}

.ngdialog.ngdialog-closing .ngdialog-overlay {
	-webkit-backface-visibility: hidden;
	-webkit-animation: ngdialog-fadeout 0.5s;
	animation: ngdialog-fadeout 0.5s;
}

.ngdialog-content {
	background: white;
	-webkit-backface-visibility: hidden;
	-webkit-animation: ngdialog-fadein 0.5s;
	animation: ngdialog-fadein 0.5s;
	pointer-events: all;
}

.ngdialog.ngdialog-closing .ngdialog-content {
	-webkit-backface-visibility: hidden;
	-webkit-animation: ngdialog-fadeout 0.5s;
	animation: ngdialog-fadeout 0.5s;
}

.ngdialog-close:before {
	font-family: 'Helvetica', Arial, sans-serif;
	content: '\00D7';
	cursor: pointer;
}

html.ngdialog-open,
body.ngdialog-open {
	overflow: hidden;
}