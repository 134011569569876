.user_schedule {
	background-color: $colorDarkGrey;
	
	color: #fff;
}

.user_schedule-scrollable_content {
	padding: gridUnit(3) gridUnit(2);
}

.user_schedule-title {
	font-size: pxToRem(24);
	letter-spacing: .4/24 * 1em;
	text-align: center;
}

.user_schedule-content {
	margin-top: gridUnit(4);
}

.user_schedule-festival-name {
	display: block;
	padding-top: gridUnit(1);
	padding-bottom: gridUnit(1);
	
	font-weight: 900;
	font-size: pxToRem(15);
	letter-spacing: .3/15 * 1em;
}

.user_schedule-show {
	position: relative;
	
	padding-top: gridUnit(.5);
	padding-bottom: gridUnit(.5);
	
	border-top: 1px solid rgba(#fff, .1);
}

.user_schedule-show-button {
	display: flex;
	align-items: center;
	text-align: left;
	
	cursor: default;
	
	&--playing {
		cursor: pointer;
	}
}

.user_schedule-show-image {
	display: block;
	width: pxToRem(120);
	
	opacity: .5;
	
	transition: $defaultTransition;
	
	& + .user_schedule-show-content {
		padding-left: gridUnit(1);
	}
	
	.user_schedule-show:hover & {
		opacity: 1;
	}
}

.user_schedule-show-artist_image {
	display: block;
	width: 100%;
	height: 0;
	padding-bottom: 8/12 * 100%;
	
	background-size: cover;
}

.user_schedule-show-content {
	display: block;
}

.user_schedule-show-artist_name {
	display: block;
	padding-right: gridUnit(2);

	font-weight: 900;
	font-size: pxToRem(18);
	letter-spacing: .4/18 * 1em;
	color: rgba(#fff, .8);
	
	transition: $defaultTransition;
	
	.user_schedule-show:hover & {
		color: #fff;
	}
}

.user_schedule-show-meta {
	display: block;
	margin-top: gridUnit(.5);
	
	line-height: 1.5;
}

.user_schedule-show-status {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding: pxToRem(6) pxToRem(12) pxToRem(4) pxToRem(12);
	margin-right: gridUnit(.5);
	margin-bottom: gridUnit(.5);
	
	background-color: $colorDarkGrey;
	
	border-radius: pxToRem(12);
	
	color: $strongCyan;
	font-size: pxToRem(10);
	font-weight: 900;
	line-height: 1;
	letter-spacing: 1/10 * 1em;
	text-transform: uppercase;
	
	&:before {
		content: '';
		
		position: absolute;
		z-index: -2;
		left: pxToRem(-2);
		right: pxToRem(-2);
		top: pxToRem(-2);
		bottom: pxToRem(-2);
		
		border-radius: pxToRem(12);
		
		background: linear-gradient(to left, $strongCyan, $aquaGreen);
	}

	&--playing {		
		background-color: transparent;
		
		color: white;
	}
}

.user_schedule-show-time,
.user_schedule-show-place {
	display: inline-block;

	font-size: pxToRem(14);
	letter-spacing: .4/14 * 1em;
	color: rgba(#fff, .6);
}

.user_schedule-show-remove {
	position: absolute;
	top: pxToRem(7);
	right: pxToRem(-4);
	
	padding: gridUnit(.25);
	overflow: hidden;
	
	color: rgba(#fff, .6);
	line-height: 1;
	
	transition: $defaultTransition;
	
	&:hover {
		overflow: visible;

		color: #fff;
	}
}

.user_schedule-show-remove-icon {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: pxToRem(18);
	height: pxToRem(18);
	
	border: 1px solid;
	border-radius: 50%;
	
	transition: $defaultTransition;
	
	.user_schedule-show-remove:hover & {
		transform: scale(1.2);
	}
	
	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: pxToRem(-4);
		margin-top: pxToRem(-4);
		width: pxToRem(8);
		height: pxToRem(8);
	}
	
	path {
		stroke: rgba(#fff, .6);
		
		transition: $defaultTransition;
		
		.user_schedule-show-remove:hover & {
			stroke: #fff;
		}
	}
}

.user_schedule-show-remove-text {
	position: absolute;
	right: pxToRem(36);
	top: pxToRem(-7);
	
	display: inline-block;
	padding: pxToRem(13) pxToRem(17) pxToRem(12) pxToRem(17);
	
	background-color: $mediumGrey;
	
	border-radius: pxToRem(4);
	
	font-size: pxToRem(13);
	letter-spacing: .4/13 * 1em;
	color: #fff;
	white-space: nowrap;
	
	opacity: 0;

	transition: $defaultTransition;
	
	.user_schedule-show-remove:hover & {
		opacity: 1;
	}
	
	&:before {
		position: absolute;
		left: 100%;
		top: 50%;
		margin-top: pxToRem(-8);
		
		content: '';
		
		width: 0;
		height: 0;
		border-style: solid;
		border-width: pxToRem(8) 0 pxToRem(8) pxToRem(8);
		border-color: transparent transparent transparent $mediumGrey;

	}
}

.user_schedule-close {
	position: absolute;
	right: gridUnit(1);
	top: gridUnit(1);
	padding: pxToRem(6) pxToRem(6) pxToRem(5) pxToRem(6);
}

.user_schedule-close-text {
	@include hidden-visually;
}

.user_schedule-close-icon {
	display: inline-block;
	width: pxToRem(18);
	height: pxToRem(18);

	path {
		stroke: rgba(#fff, .6);
		
		transition: $defaultTransition;
		
		.user_schedule-close:hover & {
			stroke: #fff;
		}
	}
}

.user_schedule-nothing {
	text-align: center;
}
