@mixin decorator_shape_gradient_straight {
	background-image: url(../images/decorator_shapes/straight.svg);
	background-repeat: no-repeat;
	background-size: contain;

	width: pxToRem(117);
	height: pxToRem(89);
}

@mixin decorator_shape_gradient_curve1 {
	background-image: url(../images/decorator_shapes/curve_1.svg);
	background-repeat: no-repeat;
	background-size: contain;

	width: pxToRem(182);
	height: pxToRem(166);
}
