.hero_stripe_section {
	@include deco_sections_default;
	
	transform: translateY( #{pxToRem(-48)} );
}

.hero_stripe_section-container {
	@include deco_containers_large;
}

.hero_stripe_section-content {
	@include deco_alignement_spaceBetween_center;
	@include deco_typo_body_large;

	height: pxToRem(96);
	padding: pxToRem(40);

	color: #fff;

	background-image: $gradient_purple_pink;
}

@media only screen and (max-width: $ipadPortrait - 1px) {
	.hero_stripe_section-content {
		flex-direction: column;
		height: auto;
		
		text-align: center;
	}
	
	.hero_stripe_section-dates {
		margin-bottom: pxToRem(20);
	}
}
