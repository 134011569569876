.artist_panel {
	height: calc(100vh - 64px);

	&:after {
		content: "";

		position: absolute;
		bottom: 0;
		left: 0;
		height: pxToRem(72);
		width: 100%;

		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.0), #fff);
	}
}

.artist_panel-content {
	&.artist_panel-content--animated {
		.artist_panel-hero_content {
			opacity: 1;
		}
	}
}

.artist_panel-close_btn {
	position: absolute;
	z-index: 1;
	right: pxToRem(8);
	top: pxToRem(18);

	padding: gridUnit(.25);
	overflow: hidden;
	
	color: rgba(#fff, .6);
	line-height: 1;
	
	transition: $defaultTransition;
	
	&:hover {
		overflow: visible;

		color: #fff;
	}
}

.artist_panel-close_btn-icon {
	display: inline-block;
	vertical-align: middle;
	width: pxToRem(18);
	height: pxToRem(18);
	
	background-color: rgba(34,30,31,0.6);
	
	border: pxToRem(1) solid;
	border-radius: 50%;
	
	transition: $defaultTransition;
	
	.artist_panel-close_btn:hover & {
		transform: scale(1.2);
	}
	
	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: pxToRem(-4);
		margin-top: pxToRem(-4);
		width: pxToRem(8);
		height: pxToRem(8);
	}
	
	path {
		stroke: rgba(#fff, .6);
		
		transition: $defaultTransition;
		
		.user_schedule-show-remove:hover & {
			stroke: #fff;
		}
	}
 }

//
// Hero section
//

.artist_panel-hero_container {
	height: pxToRem(256);
	width: 100%;

	position: absolute;
	top:0;
	left: 0;

	transform: translateZ(0);
	z-index: 1;
}

.artist_panel-hero_image {
	@include deco_bg_center;
	height: 100%;
	width: 100%;

	position: absolute;;
	top:0;
	left: 0;

	transform: translateZ(0);
}

.artist_panel-hero_overlay {
	position: absolute;

	left: 0;
	top: 0;
	width: 100%;
	height: 100%;

	&--darkness {
		background-color: $colorDarkGrey;
	}

	&--gradient {
		background-image: $gradient_purple_pink;
	}
}

.artist_panel-hero_content {
	position: relative;

	color: #fff;
	padding: 90px 64px 0;

	opacity: 0;

	transition: $defaultTransition;
}

//
// Main content
//

.artist_panel-content {
	position: relative;

	padding: pxToRem(300) pxToRem(64) pxToRem(50);
	max-height: 100%;

	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;

	color: #404040;
}

.artist_panel-content_country {
	@include deco_typo_body_small;

	display: block;
	margin-bottom: pxToRem(12);
}

.artist_panel-content_name {
	@include deco_typo_heading_3-bold;

	margin-bottom: pxToRem(32);
}

.artist_panel-content_biography {
	color: $darkGrey;

	p:first-child {
		@include deco_typo_body_large;

		position: relative;
		padding: pxToRem(10) 0 pxToRem(10) pxToRem(18);
		margin-bottom: pxToRem(40);

		font-weight: 500;
		color: #404040;

		&:before {
			content: "";

			position: absolute;
			left: pxToRem(-8);
			top: 0;
			height: 100%;
			width: pxToRem(8);

			border-radius: pxToRem(8);
			background-image: linear-gradient(to bottom, $pink, $purple);
		}
	}
}

@media only screen and (max-width: $panelWidth) {
	.artist_panel-content {
		padding-left: gridUnit(2);
		padding-right: gridUnit(2);
	}
}
