////
/// Project's variables
////

/*
* Grid and Spacings
*/

/// Very Large Container Size - 1440px
$containerVeryLarge: gridUnit(113);

/// Large Container Size - 1168px
$containerLarge: gridUnit(73);

/// Very Large screens gutter size - 32px
$gutterVeryLargeScreens: gridUnit(2);

/// Large screens gutter size - 16px
$gutterLargeScreens: gridUnit(1);

/*
* Fonts
*/

/// Default project font size
$defaultFontSize: 16 !default;

/// Font family for default text
$fontText: 'MuseoSansRounded', Arial, sans-serif !default;

/*
* Colors
*/
$colorDarkGrey: #221e1f;
$strongCyan: #08adce;
$aquaGreen: #64c5b1;
$pink: #bd4c9c;
$purple: #6e3694;
$darkGrey: #474747;
$mediumGrey: #818285;
$lightGrey: #cdcdcd;

$gradient_strongCyan_aquaGreen: linear-gradient(to left, $strongCyan, $aquaGreen);
$gradient_aquaGreen_strongCyan: linear-gradient(to left, $aquaGreen, $strongCyan);
$gradient_pink_purple: linear-gradient(to left, $pink, $purple);
$gradient_purple_pink: linear-gradient(to left, $purple, $pink);
$gradient_black_grey: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0.1) 80%,rgba(0,0,0,0) 100%);

$colorWarning: #d94268;
$colorText: $colorDarkGrey;
$colorTextInvert: #fff;
$colorSelection: $strongCyan;
$backgroundSectionColor: #fff;
$colorBackgroundTile: #fff;

/*
* Responsive design
*/

/// Medium screen media query - 1280px
$responsiveLargeScreenThreshold: gridUnit(80);

/// Medium screen media query - 1024px
$responsiveMediumScreenThreshold: gridUnit(64);

/// Small screen media query - 640px
$responsiveSmallScreenThreshold: gridUnit(40);

/*
* zIndexes
*/
$zIndexLoadingPanel: 10;
$zIndexScrollIndicator: 20;
$zIndexModalOverlay: 90;
$zIndexHeader: 100;
$zIndexLivestreamFooter: 110;
$zIndexModal: 120;

/*
* Miscellaneous
*/

/// Height for an hour in the schedule
$heightHour: gridUnit(45);

/// Height of the livestream footer
$heightLivestreamFooter: gridUnit(4);

/// Default transition
$defaultTransition: 200ms all ease-in-out;

// Breakpoints
$ipadLandscape: 1024px;
$ipadPortrait: 768px;
$smallViewport: 960px;
$splashBoxWidth: 608px;
$iphone5Landscape: 568px;
$panelWidth: 480px;
