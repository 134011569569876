.mainstage_timeline {
	background: $colorDarkGrey;
	color: $colorTextInvert;
}

.mainstage_timeline-header {
	padding: gridUnit(3.75) gridUnit(1) gridUnit(2.25) gridUnit(1);
}

.mainstage_timeline-title {
	font-weight: 900;
	font-size: pxToRem(12);
	line-height: 0.8;
	opacity: 0.5;
	text-transform: uppercase;
	text-align: center;
	
	&--loading {
		text-transform: none;
	}
}

.mainstage_timeline-title-loader {
	display: inline-block;
	width: pxToRem(32);
	margin-bottom: pxToRem(12);
}

.mainstage_timeline-tabs_wrapper {
	position: relative;
	padding: gridUnit(1) gridUnit(1) 0 gridUnit(1);
	margin-bottom: gridUnit(4);

 	&::before,
 	&::after {
 		content: '';
 		position: absolute;
 		z-index: -1;
 		top: gridUnit(1.75);
 		bottom: 0;
 		width: gridUnit(3.5);
 		opacity: 0;
 		pointer-events: none;
 		transition: $defaultTransition;
 	}

 	&::before {
 		left: 0;
 		background-image: linear-gradient(to left, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5));
 	}

 	&::after {
 		right: 0;
 		background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5));
 	}

 	&.horizontal_scroll--left {
 		&::before {
 			z-index: $zIndexScrollIndicator;
 			opacity: 1;
 		}
 	}
 	&.horizontal_scroll--right {
 		&::after {
 			z-index: $zIndexScrollIndicator;
 			opacity: 1;
 		}
 	}
 }

.mainstage_timeline-tabs {
	@include deco_alignment_center_start;
	overflow-x: auto;
	overflow-y: hidden;
	
	-webkit-overflow-scrolling: touch;
	
	.horizontal_scroll--left &,
	.horizontal_scroll--right & {
		justify-content: flex-start;
	}
}

.mainstage_timeline-tabs-item {
	display: block;
	position: relative;
	padding: 0 0 pxToRem(12);
	margin: gridUnit(1.5);
	opacity: 0.5;
	text-align: center;
	color: inherit;
	font: inherit;
	white-space: nowrap;

	&:hover,
	&:focus {
		outline: none;

		&::after {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}

	&::after {
		content: '';
		display: block;
		height: pxToRem(6);
		width: gridUnit(6);
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: gridUnit(-3);
		border-radius: pxToRem(6);
		background: $colorSelection;
		opacity: 0;
		transform: translate3d(0, 100%, 0);
		transition: $defaultTransition;
	}

	&--active {
		opacity: 1;

		&::after {
			opacity: 1;
			transform: translate3d(0, 0, 0);
		}
	}
}

.mainstage_timeline-tabs-item-day {
	@include deco_typo_body_small;
	display: block;
	margin-bottom: pxToRem(4);
}
.mainstage_timeline-tabs-item-date {
	@include deco_typo_heading_3-bold;
	display: block;
}


.mainstage_timeline-empty {
	@include deco_typo_body_regular;

	padding: 0 gridUnit(1) gridUnit(3.75);
	text-align: center;
}

@media only screen and (max-width: $iphone5Landscape) {
	.mainstage_timeline-tabs-item {
		margin: gridUnit(1);
	}
}
