.livestream_section {
	position: relative;

	width: 100vw;
	height: 100vh;

	background-color: black;
}

.livestream_section-player_size_toggle {
	position: absolute;
	z-index: 2;
	bottom: pxToRem(84);
	right: pxToRem(20);
	width: pxToRem(24);
	height: pxToRem(24);
	padding: 0;
	
	color: white;
}

.livestream_footer-player_size_toggle-arrow {
	position: absolute;
	right: 0;
	top: 0;
	display: block;
	width: pxToRem(9);
	height: pxToRem(9);
	
	svg {
		display: block;
	}
	
	transition: $defaultTransition;
	
	.livestream_section-player_size_toggle--active & {
		transform: rotate(180deg);
	}
}

.livestream_footer-player_size_toggle-square {
	display: block;
	width: 100%;
	height: 100%;
}

.livestream_section-player {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: calc(100% - #{$heightLivestreamFooter});
	border: none;
	
	transform: scale(.55212);
	
	transition: $defaultTransition;
	
	&--full {
		transform: scale(1);
	}
}

.livestream_section-player_size_toggle-text {
	position: absolute;
	right: 100%;
	top: 50%;
	padding: pxToRem(14) pxToRem(30) pxToRem(13) pxToRem(30);
	margin-top: pxToRem(-20);
	margin-right: pxToRem(16);
	
	background-color: $mediumGrey;
	
	border-radius: pxToRem(2);
	
	font-size: pxToRem(13);
	line-height: 1;
	white-space: nowrap;
	
	opacity: 0;
	visibility: hidden;
	transform: translate3d(#{pxToRem(10)}, 0, 0);
	
	transition: $defaultTransition;
	
	.livestream_section-player_size_toggle:hover & {
		opacity: 1;
		visibility: visible;;
		transform: translate3d(0, 0, 0);
	}
	
	&:before {
		position: absolute;
		right: pxToRem(-9);
		top: 50%;
		margin-top: pxToRem(-9);
		
		content: '';
		
		width: 0;
		height: 0;
		border-style: solid;
		border-width: pxToRem(9) 0 pxToRem(9) pxToRem(9);
		border-color: transparent transparent transparent $mediumGrey;

	}
}
