.hero_section-wrapper {
	display: block;
	overflow: hidden;
}

.hero_section {
	@include deco_sections_default;

	height: pxToRem(568);
	
	&:before,
	&:after {
		content: '';

		position: absolute;
		z-index: 1;
		display: block;
		
		background-repeat: no-repeat;
		background-size: cover;
		
		animation: floating 10s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
	}
	
	&:before {
		left: pxToRem(-30);
		top: 98%;
		
		background-image: url(../images/decorator_shapes/curve_2.svg);

		width: pxToRem(97);
		height: pxToRem(64);
	}
	
	&:after {
		right: pxToRem(-50);
		top: 20%;
		
		background-image: url(../images/decorator_shapes/curve_3.svg);
		
		width: pxToRem(151);
		height: pxToRem(182);
		
		animation-delay: 3s;
	}
}

.hero_section-container {
	position: relative;
	height: 100%;
}

.hero_section-background {

	@include deco_bg_center;
	@include deco_bg_black_layer;

	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}

.hero_section-content {
	@include deco_alignment_center_center;

	position: relative;

	height: 100%;
}

.hero_section-content-container {
	max-width: pxToRem(600);
	padding-left: gridUnit(2);
	padding-right: gridUnit(2);

	text-align: center;
}

.hero_section-suptitle {
	@include deco_buttons_inactive_info;

	margin-bottom: pxToRem(10);
}

.hero_section-title {
	@include deco_typo_heading_1--large;

	color: #fff;
}

@media only screen and (max-width: $iphone5Landscape) {
	.hero_section {
		height: gridUnit(20);
	}

	.hero_section-title {
		font-size: pxToRem(40);
		letter-spacing: .6/40 * 1em;
	}
}

@media only screen and (max-height: $ipadPortrait - 1px) {
	.hero_section {
		height: gridUnit(20);
	}

	.hero_section-title {
		font-size: pxToRem(40);
		letter-spacing: .6/40 * 1em;
	}
}

@media only screen and (max-width: $iphone5Landscape) {
	.hero_section {
		&:before,
		&:after {
			display: none;
		}
	}
}
