.page_404 {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100vh;
	
	background: $gradient_pink_purple;
	
	color: #fff;
	text-align: center;
}

.page_404-logo {
	width: pxToRem(70);
}

.page_404-title {
	margin-top: gridUnit(2);
	margin-bottom: gridUnit(2);
	
	font-weight: 900;
	font-size: pxToRem(100);
	letter-spacing: .4/100 * 1em;
}

.page_404-body {
	font-size: pxToRem(15);
	letter-spacing: .3/15 * 1em;
	
	p {
		margin-top: 0;
	}
}

.page_404-footer {
	margin-top: gridUnit(3);
}