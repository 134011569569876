@mixin deco_alignment_center_center {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin deco_alignment_center_start {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

@mixin deco_alignment_start_center {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

@mixin deco_alignement_spaceBetween_center {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
