.connect_section {
	@include deco_bg_center;
	@include deco_alignment_center_center;

	min-height: 100vh;
	min-width: 100vw;

	background-image: url(../images/connect_bg.jpg);
}

.connect_section-container {
	width: 100%;
	max-width: pxToRem(608);
	margin-top: gridUnit(7);
	margin-bottom: gridUnit(7);
	padding: 0 $gutterLargeScreens;

	text-align: center;

	position: relative;

	z-index: 1;
	&:before {
		content: "";

		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		top: pxToRem(-50);
		width: pxToRem(168);
		height: pxToRem(168);

		border-radius: pxToRem(168);
		background-color: #fff;

		z-index: -1;
	}

	&:after {
		content: "";

		position: absolute;
		margin: auto;
		top: pxToRem(-25);
		left: 0;
		right: 0;
		width: pxToRem(49);
		height: pxToRem(49);

		background: url(../images/logo.svg) no-repeat center center transparent;
		background-size: 100%;
	}
}

.connect_section-main {
	position: relative;
	padding: gridUnit(3) gridUnit(1.5) gridUnit(1.5);

	background-color: #fff;
	&:before {
		content: "";

		@include decorator_shape_gradient_straight;

		position: absolute;
		top: pxToRem(120);
		right: pxToRem(-60);

		width: pxToRem(117);
		height: pxToRem(89);
	}

	&:after {
		content: "";

		@include decorator_shape_gradient_curve1;

		position: absolute;
		bottom: pxToRem(-40);
		left: pxToRem(-112);
	}
	
	&:before,
	&:after {
		animation: floating 10s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
	}
	
	&:after {
		animation-delay: 3s;
		
	}
}

.connect_section-main-content {
	max-width: gridUnit(22);
	margin-left: auto;
	margin-right: auto;
}

.connect_section-main-header {
	margin-top: pxToRem(16);
	margin-bottom: pxToRem(40);
}

.connect_section-title {
	@include deco_typo_heading_2-bold;
}

.connect_section-description {
	margin-bottom: 0;
}

.connect_section-main-form {
	position: relative;
	overflow: hidden;

	margin-bottom: pxToRem(24);
	padding-bottom: pxToRem(24);

	z-index: 1;
	&:before {
		content: "";

		position: absolute;
		bottom: pxToRem(7);
		left: 0;
		right: 0;
		height: pxToRem(1);
		width: 100%;

		background: $lightGrey;

		z-index: -2;
	}

	&:after {
		content: 'or';

		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		width: pxToRem(32);
		margin: auto;

		text-align: center;
		font-size: pxToRem(14);
		background: #fff;

		z-index: -1;
	}
}

.connect_section-link {
	@include deco_typo_body_small
	display: block;

	margin-top: pxToRem(32);
	width: 100%;

	letter-spacing: pxToRem(0.4);
	text-align: center;
	text-decoration: none;
	span {
		@include deco_anim_hover_underline;

		color: $strongCyan;
	}
}

.connect_section-out_link {
	@include deco_typo_body_vsmall;

	margin-top: pxToRem(14);
	color: #fff;
	text-decoration: none;

	opacity: 0.6;

	transition: $defaultTransition;

	&:hover {
		opacity: 1;
	}
	
	.btn-icon-text + .icon {
		margin-left: gridUnit(.5);
	}
	
	.icon {
		width: pxToRem(6);
	}
}

@media only screen and (max-width: $splashBoxWidth) {
	.connect_section-main {
		&:before,
		&:after {
			display: none;
		}
	}
}
