.form {
	+ .form {
		margin-top: gridUnit(2);
	}
}

.form-fieldset {
	&:not(:last-child) {
		margin-bottom: pxToRem(50);
		.form-row:last-child {
			margin-top: 0;
		}
	}
}

.form-fieldset-title {
	@include deco_typo_heading_6-bold;

	margin-bottom: pxToRem(32);
}

.form-row {
	display: flex;

	margin: 0 pxToRem(-8);
}

.form-input {
	flex: 1 1 50%;

	padding: pxToRem(8);
}

.form-input-text_error {
	@include deco_typo_body_vsmall;
	display: none;

	text-align: left;
	color: $colorWarning;

	&--global {
		display: block;

		text-align: center;
	}
}

.form-input-text_success {
	@include deco_typo_body_vsmall;
	display: none;

	text-align: left;
	color: $strongCyan;
	&--global {
		display: block;
		text-align: center;
	}
}

.form-input--error {
	input,
	select {
		border: 1px solid $colorWarning;
	}

	.form-input-text_error {
		display: block;
	}
}

@media only screen and (max-width: $panelWidth) {
	.form-row {
		flex-direction: column;
	}
}
