.mainstage_timeline_schedule {
	padding: 0 0 gridUnit(10);
}

.mainstage_timeline_schedule-loading {
	@include deco_typo_body_regular;

	padding: 0 gridUnit(1);
	text-align: center;
}

.mainstage_timeline_schedule-content_wrapper {
	text-align: center;
	position: relative;
}

.mainstage_timeline_schedule-content {
	display: inline-block;
	max-width: 100%;
	text-align: left;
	position: relative;
}

.mainstage_timeline_schedule-times {
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: gridUnit(4);
	padding-top: gridUnit(3.5);
	
	@media (max-width: $panelWidth) {
		width: gridUnit(2.5);
	}
}

.mainstage_timeline_schedule-times-item {
	@include deco_typo_body_small;

	position: relative;
	height: $heightHour;
	text-align: right;
	padding-left: gridUnit(0.5);
	padding-right: gridUnit(0.5);
	color: $mediumGrey;
	text-transform: lowercase;

	&::after {
		content: '';
		position: absolute;
		top: gridUnit(0.25);
		bottom: gridUnit(0.25);
		right: 0;
		border-right: 1px solid $colorTextInvert;
		opacity: 0.2;
	}
}

.mainstage_timeline_schedule-stages_wrapper {
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: auto;
	max-width: calc(100% - #{gridUnit(4)});
	
	@media (max-width: $panelWidth) {
		max-width: calc(100% - #{gridUnit(2.5)});
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		z-index: -1;
		top: gridUnit(1.75);
		bottom: 0;
		width: gridUnit(3.5);
		opacity: 0;
		pointer-events: none;
		transition: $defaultTransition;
	}

	&::before {
		left: 0;
		background-image: linear-gradient(to left, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5));
	}

	&::after {
		right: 0;
		background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5));
	}

	&.horizontal_scroll--left {
		&::before {
			z-index: $zIndexScrollIndicator;
			opacity: 1;
		}
	}
	&.horizontal_scroll--right {
		&::after {
			z-index: $zIndexScrollIndicator;
			opacity: 1;
		}
	}
}

.mainstage_timeline_schedule-stages {
	display: block;
	position: relative;
	width: auto;
	overflow-x: auto;
	overflow-y: hidden;
	
	-webkit-overflow-scrolling: touch;
}

.mainstage_timeline_schedule-stages-content {
	@include deco_alignment_start_center;

	flex-wrap: nowrap;
}

.mainstage_timeline_schedule-now_indicator {
	position: absolute;
	left: pxToRem(15);
	right: 0;
	border-top: pxToRem(1) solid $aquaGreen;
	border-bottom: pxToRem(1) solid $strongCyan;
	transform: translateY(#{gridUnit(3.5)}); // Add the schedule stages name height
	pointer-events: none;

	&::before {
		content: 'NOW';
		position: absolute;
		left: 0;
		top: 0;
		background: $gradient_strongCyan_aquaGreen;
		color: $colorTextInvert;
		transform: translateY(-50%);
		font-size: pxToRem(8);
		line-height: 1;
		text-align: center;
		font-weight: 900;
		padding: pxToRem(4) pxToRem(8);
		border-radius: pxToRem(16);
	}
}

.mainstage_timeline_schedule-now_indicator-offset {
	position: absolute;
	left: 0;
	top: -50vh;
}
