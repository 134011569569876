@mixin deco_bg_center {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin deco_bg_black_layer {
	&:after {
		content: "";

		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		background-color: $colorDarkGrey;
		opacity: 0.6;
	}
}
