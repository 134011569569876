.is_loading {
	&--disabled {
		.is_loading-overlay {
			opacity: 0;
			visibility: hidden;
		}
	}

	&--enabled {
		.header {
			display: none;
		}
		
		.is_loading-overlay {
			opacity: 1;
			visibility: visible;
		}
	}
}

.is_loading-overlay {
	@include deco_alignment_center_center;
	position: fixed;
	z-index: $zIndexLoadingPanel;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: linear-gradient(to bottom, $aquaGreen, $strongCyan);
	
	font-size: pxToRem(18);
	letter-spacing: .4/18 * 1em;
	font-weight: 900;
	color: $colorTextInvert;
	text-align: center;
	
	transition: all 200ms ease-in-out;
}

.is_loading-overlay-loader {
	display: inline-block;
	width: pxToRem(64);
	margin-bottom: pxToRem(30);
	
	fill: white;
}
