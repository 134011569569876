.footer_section {
	background-color: #fff;

	padding: 0 pxToRem(48);
}

.footer_section-container {
	@include deco_sections_padding_16;
}

.footer_section-content {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	height: 60px;
}

.footer_section-logo {
	position: absolute;
	left: 0;
	right: 0;
	text-align: center;
}

.footer_section-logo-link {
	display: inline-block;
	text-decoration: none;
}

.footer_section-logo-icon {
	width: 33px;
	height: 33px;
}

.footer_section-text {
	display: block;
	position: relative;

	color: $mediumGrey;
	font-size: pxToRem(12);
	text-align: center;
	letter-spacing: pxToRem(0.4);
}
