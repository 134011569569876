@mixin deco_anim_hover_underline {
	&:hover {
		text-decoration: underline;
	}
}

@mixin deco_anim_underline {
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

@mixin deco_anim_opacity {
	opacity: 0.6;

	text-decoration: none;
	
	transition: $defaultTransition;
	&:hover {
		opacity: 1;
	}
}
