@keyframes floating {
	0% {
		transform: translate3d(0, 0, 0);
	}
	5% {
		transform: translate3d(0, 0, 0);
	}
	45% {
		transform: translate3d(2%, -15%, 0);
	}
	55% {
		transform: translate3d(2%, -15%, 0);
	}
	95% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes growing {
	0% {
		transform: scaleX(0);
		transform-origin: 0 0;
	}
	100% {
		transform: scaleX(100%);
		transform-origin: 0 0;
	}
}