.stages_panel {
	position: absolute;
	left: 0;
	bottom: 100%;
	
	width: pxToRem(450);
	margin-bottom: pxToRem(8);
	
	background-color: $colorDarkGrey;
	
	color: white;
	
	opacity: 0;
	transform: translate3d(0, 10%, 0);
	transition: $defaultTransition;
	
	&--open {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.stages_panel-title {
	padding: pxToRem(25) pxToRem(45);
	
	font-size: pxToRem(15);
	letter-spacing: .3/15 * 1em;
	line-height: 1;
}

.stages_panel-content {
	border-top: pxToRem(1) solid $darkGrey;
}

.stages_panel-list-link {
	position: relative;
	display: block;
	padding: pxToRem(18) pxToRem(20) pxToRem(18) pxToRem(90);
	
	color: white;
	text-decoration: none;
	
	opacity: .6;
	
	transition: $defaultTransition;
	
	&:hover,
	&--active {
		opacity: 1;
	}
	
	&:hover {
		background: linear-gradient(to left, $strongCyan, $aquaGreen);
	}
	
	&:before {
		content: '';
		
		position: absolute;
		top: 50%;
		left: pxToRem(45);
		
		width: pxToRem(8);
		height: pxToRem(8);
		margin-right: pxToRem(20);
		margin-top: pxToRem(-4);
		
		border: pxToRem(1) solid;
		border-radius: 50%;
		
		transition: $defaultTransition;
	}
	
	&--active {
		&:before {
			content: 'ON';
			
			width: pxToRem(38);
			height: pxToRem(24);
			padding: pxToRem(6);
			margin-left: pxToRem(-16);
			margin-top: pxToRem(-12);
			
			background: linear-gradient(to left, $strongCyan, $aquaGreen);
			
			border: none;
			border-radius: pxToRem(12);
			
			font-size: pxToRem(10);
			font-weight: 900;
			text-align: center;
		}
	}
}

@media only screen and (max-width: $ipadPortrait - 1px) {
	.stages_panel {
		width: 100vw;
		left: pxToRem(-81);
	}
}