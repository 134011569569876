/*
 * Carousel Animation
 */
@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.animate-fadeIn {
	animation: fadeIn 800ms ease;
}

/*
 * Carousel Style
 */
.slider {
	position: relative;
	
	background-color: $backgroundSectionColor;
	
	text-align: center;
}

.slider-container {
	@include deco_containers_large;
}

.slider-inner {
	position: relative;
	padding-bottom: gridUnit(1);
}

.slider-item {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	display: flex;
	width: 100%;
	padding: 0 gridUnit(4) gridUnit(2) gridUnit(4);
	min-height: gridUnit(27);
	
	visibility: hidden;
	
	text-align: left;

	&--active {
		position: relative;
		z-index: 2;
		visibility: visible;
	}
	
	&--visible {
		visibility: visible;
	}
}

.slider-item-col {
	position: relative;
	flex-basis: 50%;
	padding: gridUnit(2);
}

.slider-item-hero {
	position: absolute;
	left: gridUnit(2);
	right: gridUnit(2);
	top: gridUnit(2);
	bottom: gridUnit(2);

	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.slider-title,
.slider-item-category {
	display: block;
	font-weight: 900;
	font-size: pxToRem(12);
	letter-spacing: 1/12 * 1em;
	color: $strongCyan;
	text-transform: uppercase;
	text-align: left;
}

.slider-title {
	display: none;
}

.slider-item-category {
	margin-bottom: pxToRem(24);
}

.slider-item-content {
	color: #404040;
}

.slider-item-title {
	font-size: pxToRem(35);
	letter-spacing: .4/35 * 1em;
}

.slider-item-url {
	@include wrap-link;
}

.slider-pagers,
.slider-prev,
.slider-next {
	z-index: 3;
}

.slider-pagination {
	position: absolute;
	top: 50%;
	margin-top: pxToRem(-45);
	
	color: $lightGrey;
		
	transition: $defaultTransition;
	
	&:hover {
		color: $purple;
		
		.slider-pagination-icon {
			&:before {
				transform: scale(1);
			}
		}
	}
}

.slider-pagination-text {
	@include hidden-visually();
}

.slider-pagination-icon {
	position: relative;
	display: inline-block;
	width: pxToRem(10);
	height: pxToRem(18);
	
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		display: block;
		width: gridUnit(1);
		height: pxToRem(2);
		margin-top: pxToRem(-1);
		
		background-color: $purple;
		
		border-radius: pxToRem(2);
		
		transform: scale(0, 1);
		transition: $defaultTransition;
	}
}

.slider-prev {
	left: 0;
	
	.slider-pagination-icon {
		&:before {
			left: 0;
			transform-origin: 0 0;
		}
	}
}

.slider-next {
	right: 0;

	.slider-pagination-icon {
		&:before {
			right: 0;
			transform-origin: 100% 0;
		}
	}
}

.slider-pagers {
	position: relative;
	display: inline-block;
}

.slider-pager {
	display: inline-block;
	padding: gridUnit(.25);
	margin: gridUnit(.25);
	
	color: $mediumGrey;
	
	transition: $defaultTransition;
	
	&--active {
		color: $purple;
		
		&:before {
			background-color: $purple;
		}
	}
	
	&:before {
		content: '';
		
		display: inline-block;
		width: pxToRem(7);
		height: pxToRem(7);
		
		border: 1px solid;
		border-radius: 50%;
	}
}

.slider-pager-number {
	@include hidden-visually();
}

.slider-indicator {
	display: block;
	height: pxToRem(4);
	
	background: $gradient_purple_pink;
	
	border-radius: pxToRem(2);
	
	&--active {
		animation: growing 20s;
	}
}

@media only screen and (max-width: $smallViewport) {
	.slider-title {
		position: relative;
		top: pxToRem(4);
		display: block;
	}

	.slider-item {
		padding: gridUnit(2) 0;
		
		flex-direction: column;
	}
	
	.slider-item-col {
		flex-basis: auto;
		padding: 0;
	}
	
	.slider-item-hero {
		position: static;
		
		&:before {
			content: '';
			
			display: block;
			width: 100%;
			padding-bottom: percentage(264/440);
		}
	}
	
	.slider-item-content {
		padding: gridUnit(1);
	}
	
	.slider-item-category {
		display: none;
	}
	
	.slider-item-title {
		font-size: pxToRem(30);
		letter-spacing: .4/30 * 1em;
	}
	
	.slider-pagination {
		top: 0;
		right: 0;
		
		margin-top: 0;
	}
	
	.slider-prev {
		left: auto;
		right: gridUnit(2);
	}
}
