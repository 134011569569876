.notification-box {
	position: absolute;
	top: gridUnit(4);
	right: gridUnit(.5);

	width: gridUnit(19);
	
	transform: translateY(-100%) translateZ(0);
	transition: $defaultTransition;
	
	&--active {
		transform: translateY(0) translateZ(0);
	}
}

.notifications,
.notification {
	position: relative;
	display: block;
	width: 100%;
}

.notification {
	background-color: $colorDarkGrey;
	
	color: #fff;
	
	box-shadow: 0 pxToRem(2) pxToRem(4) 0 rgba(#000, 0.71);
	
	&:hover {
		box-shadow: none;
		
		&:before {
			opacity: 1;
		}
	}
	
	&:before {
		content: '';
		position: absolute;
		z-index: 1;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: block;
		
		background: $gradient_aquaGreen_strongCyan;
		
		opacity: 0;
		transition: $defaultTransition;
	}
	
	& + .notification {
		margin-top: gridUnit(.5);
	}
}
.notification-inner {
	position: relative;
	z-index: 2;
	display: block;
	padding: gridUnit(1) pxToRem(24);
}

.notification-title {
	display: block;
	
	font-weight: 700;
	font-size: pxToRem(15);
	letter-spacing: .3/15 * 1em;
}

.notification-content {
	display: block;
	margin-top: gridUnit(.5);
	
	font-weight: 500;
	font-size: pxToRem(15);
	letter-spacing: .3/15 * 1em;
	color: rgba(#fff, .8);
}

.notification-close {
	position: absolute;
	z-index: 3;
	top: gridUnit(.5);
	right: gridUnit(.5);
	
	padding: gridUnit(.25);
	
	color: rgba(#fff, .6);
	
	transition: $defaultTransition;
	
	&:hover {
		color: #fff;
	}
}

.notification-close-icon {
	position: relative;
	display: inline-block;
	width: pxToRem(18);
	height: pxToRem(18);
	
	border: 1px solid;
	border-radius: 50%;
	
	transition: $defaultTransition;
	
	.notification-close:hover & {
		transform: scale(1.2);
	}
	
	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		margin-left: pxToRem(-4);
		margin-top: pxToRem(-4);
		width: pxToRem(8);
		height: pxToRem(8);
	}
	
	path {
		stroke: rgba(#fff, .6);
		
		transition: $defaultTransition;
		
		.notification-close:hover & {
			stroke: #fff;
		}
	}
}

.notification-close-text {
	@include hidden-visually;
}