.livestream_footer {
	@include deco_alignement_spaceBetween_center;
	position: fixed;

	bottom: 0;
	width: 100%;
	height: $heightLivestreamFooter;

	background-color: $colorDarkGrey;

	z-index: $zIndexLivestreamFooter;
}

.livestream_footer-buttons_container {
	display: flex;

	width: 100%;
	height: 100%;
	
	&:last-child {
		margin-right: -1px;
	}
}

.livestream_footer-button_container {
	position: relative;
	height: 100%;
	margin-left: -1px;

	border-left: 1px solid $darkGrey;
	border-right: 1px solid $darkGrey;
	
	&--share {
		max-width: pxToRem(180);
	}
	
	&:last-child {
		margin-left: auto;
	}
}

.livestream_footer-button {
	@include deco_typo_body_small;

	position: relative;
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	padding: pxToRem(22) pxToRem(32) pxToRem(16) pxToRem(32);

	font-weight: 500;
	color: #fff;
	text-decoration: none;

	&:after {
		content: "";

		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 0;

		background-color: $strongCyan;

		transition: $defaultTransition;
	}

	&:hover,
	&--active {
		&:after {
			height: pxToRem(6);
		}

		.livestream_footer-button-icon,
		.livestream_footer-button-text,
		.livestream_footer-button-arrow {
			opacity: 1;
		}


	}

	&--active {
		.livestream_footer-button-arrow {
			opacity: 1;
			transform: rotate(90deg);
		}
	}
}

.livestream_footer-button-text {
	margin-left: pxToRem(16);

	opacity: 0.6;

	transition: $defaultTransition;
}

.livestream_footer-button-icon {
	position: relative;
	top: pxToRem(-2);
	
	display: inline-block;
	vertical-align: middle;
	height: pxToRem(24);

	opacity: 0.6;

	transition: $defaultTransition;
	
	svg {
		width: auto;
		height: 100%;
	}
}

.livestream_footer-button-arrow {
	display: inline-block;
	vertical-align: middle;
	height: pxToRem(24);
	margin-left: pxToRem(19);

	transform: rotate(-90deg);

	opacity: 0.6;

	transition: $defaultTransition;
	
	svg {
		width: pxToRem(10);
		height: pxToRem(10);
	}
}

@media only screen and (max-width: $ipadPortrait - 1px) {
	.livestream_footer-button-text,
	.livestream_footer-button-arrow {
		display: none;
	}
}

