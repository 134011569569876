.mainstage_timeline_show {
	position: absolute;
	left: 0;
	right: 0;
	border-top: gridUnit(0.25) solid transparent;
	border-bottom: gridUnit(0.25) solid transparent;
	box-sizing: border-box;
	transition: $defaultTransition;
}

.mainstage_timeline_show-content {
	position: absolute;
	overflow: hidden;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
	padding: 0;
	margin: 0;
	appearance: none;
	text-align: left;
	
	&:hover,
	&:focus {
		.mainstage_timeline_show-illustration {
			&::after {
				opacity: 0.4;
			}
		}
	}

	&:disabled {
		cursor: default;
		
		opacity: 0.2;
	}

	.touchevents &,
	&:not(:disabled):hover,
	&:not(:disabled):focus {
		.mainstage_timeline_show-description {
			transform: translateZ(0);
		}
		.mainstage_timeline_show-cta {
			opacity: 1;
		}
	}
}

.mainstage_timeline_show-illustration {
	background-image: $gradient_purple_pink;
	background-position: top center;
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	width: 100%;
	bottom: gridUnit(6);

	&::after {
		content: '';
		background-image: $gradient_purple_pink;
		background-position: top center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: $defaultTransition;
	}
}

.mainstage_timeline_show-illustration-content {
	background-size: cover;
	background-position: center center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.mainstage_timeline_show-description {
	position: absolute;
	z-index: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	box-sizing: border-box;
	background: $colorBackgroundTile;
	color: $colorText;
	padding: gridUnit(1.5);
	transition: $defaultTransition;
	
	&--has_cta {
		padding-bottom: gridUnit(6.5);
		transform: translate3d(0, #{gridUnit(4.5)}, 0);
	}
}

.mainstage_timeline_show-artist_name {
	@include deco_typo_heading_4-bold;
}
.mainstage_timeline_show-meta {
	@include deco_typo_body_small;
	margin: pxToRem(6) 0 0;
}

.mainstage_timeline_show-meta-time {
	text-transform: lowercase;
}

.mainstage_timeline_show-cta {
	position: absolute;
	left: gridUnit(1);
	bottom: gridUnit(1.5);
	opacity: 0;
	transition: $defaultTransition;
}

.mainstage_timeline_show-cta-btn {
	min-width: gridUnit(9);
	padding-left: pxToRem(24);
	padding-right: pxToRem(24);
}

.mainstage_timeline_show-schedule_toggle {
	position: absolute;
	right: pxToRem(10);
	top: pxToRem(10);
	z-index: 1;
	
	width: pxToRem(28);
	height: pxToRem(28);
	
	border: 1px solid;
	border-radius: 50%;
	
	color: #fff;
	text-align: center;
	
	overflow: hidden;
	
	opacity: .6;

	transition: $defaultTransition;
	
	&:hover {
		opacity: 1;
		
		overflow: visible;
	}
	
	&--active {
		background-color: $pink;
		
		border-color: rgba(#fff, .5);
		
		.mainstage_timeline_show-schedule_toggle-icon_plus {
			opacity: 0;
		}
		
		.mainstage_timeline_show-schedule_toggle-icon_check {
			opacity: 1;
		}
	}
}

.mainstage_timeline_show-schedule_toggle-icon {
	position: absolute;
	left: 50%;
	top: 50%;
	display: inline-block;
	width: pxToRem(12);
	height: pxToRem(20);
	margin-left: pxToRem(-6);
	margin-top: pxToRem(-10);
	
	transition: $defaultTransition;
}

.mainstage_timeline_show-schedule_toggle-icon_plus  {
	margin-top: pxToRem(-9);
}

.mainstage_timeline_show-schedule_toggle-icon_check {
	opacity: 0;
}

.mainstage_timeline_show-schedule_toggle-text {
	position: absolute;
	right: pxToRem(36);
	top: pxToRem(-7);
	
	display: inline-block;
	padding: pxToRem(13) pxToRem(17) pxToRem(12) pxToRem(17);
	
	background-color: $colorDarkGrey;
	
	border-radius: pxToRem(4);
	
	font-size: pxToRem(13);
	letter-spacing: .4/13 * 1em;
	color: #fff;
	white-space: nowrap;
	
	opacity: 0;

	transition: $defaultTransition;
	
	.mainstage_timeline_show-schedule_toggle:hover & {
		opacity: 1;
	}
	
	&:before {
		position: absolute;
		left: 100%;
		top: 50%;
		margin-top: pxToRem(-8);
		
		content: '';
		
		width: 0;
		height: 0;
		border-style: solid;
		border-width: pxToRem(8) 0 pxToRem(8) pxToRem(8);
		border-color: transparent transparent transparent $colorDarkGrey;

	}
}

.mainstage_timeline_show-status {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding: pxToRem(6) pxToRem(12) pxToRem(4) pxToRem(12);
	
	background-color: inherit;
	
	border-radius: pxToRem(12);
	
	color: $strongCyan;
	font-size: pxToRem(10);
	font-weight: 900;
	line-height: 1;
	letter-spacing: .8/10 * 1em;
	text-transform: uppercase;
	
	&:before {
		content: '';
		
		position: absolute;
		z-index: -2;
		left: pxToRem(-2);
		right: pxToRem(-2);
		top: pxToRem(-2);
		bottom: pxToRem(-2);
		
		border-radius: pxToRem(12);
		
		background: linear-gradient(to left, $strongCyan, $aquaGreen);
	}
	
	.mainstage_timeline_show-description & {
		position: absolute;
		right: pxToRem(8);
		top: pxToRem(8);
	}
}
