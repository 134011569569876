ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

button {
	outline: none;
	border: none;
	background-color: transparent;
}

em {
	font-style: normal;
}

p {
	@include deco_typo_body_regular;
}

strong {
	font-weight: 900;
}

a {
	@include deco_typo_body_regular;

	text-decoration: underline;
	color: $colorText;

	&:hover {
		text-decoration: none;
	}
}

h1 {
	@include deco_typo_heading_1;
	margin: 0;
}

h2 {
	@include deco_typo_heading_2;
	margin: 0;
}

h3 {
	@include deco_typo_heading_3;
	margin: 0;
}

h4 {
	@include deco_typo_heading_4;
	margin: 0;
}

h5 {
	@include deco_typo_heading_5;
	margin: 0;
}

h6 {
	@include deco_typo_heading_6;
	margin: 0;
}
