.mainstage_timeline_stage {
	min-width: gridUnit(18);
	width: gridUnit(22);
	padding: 0 gridUnit(0.25);

	&:hover {
		.mainstage_timeline_stage-header {
			opacity: 1;
		}
	}

	&:first-child {
		padding-left: gridUnit(2);
		
		@media (max-width: $panelWidth) {
			padding-left: 0;
		}
	}
}

.mainstage_timeline_stage-header {
	opacity: 0.5;
	transition: $defaultTransition;
	box-sizing: border-box;
	padding-bottom: gridUnit(1.75);
}

.mainstage_timeline_stage-title {
	@include deco_typo_body_small;

	text-align: center;
	color: $mediumGrey;
	border-bottom: 1px solid $mediumGrey;
	line-height: gridUnit(1.75);
	height: gridUnit(1.75);
	box-sizing: border-box;
}

.mainstage_timeline_stage-content {
	position: relative;
}
