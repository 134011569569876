.header {
	position: fixed;
	width: 100%;

	z-index: $zIndexHeader;

	transform: translateZ(0);
	transition: $defaultTransition;
	
	&:before {
		content: '';
		
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: pxToRem(112);
		
		background: $gradient_black_grey;
	}
}

.header-container {
	position: relative;
	padding: 0 gridUnit(3);
	height: pxToRem(64);

	transition: $defaultTransition;
}

.header-content_container {
	@include deco_alignement_spaceBetween_center;
	
	height: 100%;
}

.header-home_link {
	display: block;
}

.header-home_link-icon {
	display: block;
	width: pxToRem(39);
	height: pxToRem(39);

	transition: $defaultTransition;
}

.header-content-side {
	display: flex;
	align-items: center;
}

.header-buttons_list {
	@include deco_alignment_center_center;
}

.header-button {
	@include deco_typo_body_small_bold;
	position: relative;
	padding: 0;

	color: #fff;
	text-decoration: none;
	
	opacity: 0.8;
	
	transition: $defaultTransition;

	&:hover {
		opacity: 1;
	}

	+ .header-button {
		&:before {
			content: '';

			display: inline-block;
			vertical-align: middle;
			width: pxToRem(1);
			height: pxToRem(16);
			margin-left: gridUnit(1);
			margin-right: gridUnit(1);

			background-color: rgba(255, 255, 255, 0.5);
		}
	}
}

.header-button_back {
	@include deco_anim_opacity;
	display: block;

	color: #fff;

	.icon {
		margin-right: pxToRem(8);
		svg {
			transform: rotate(180deg);
			width: pxToRem(8);
			height: pxToRem(11);
		}
	}
}

// Header style on scroll (header--small)
.header.header--small {
	background-color: #1D1B1C;

	box-shadow: 0 pxToRem(2) pxToRem(10) rgba(0,0,0,0.6);

	.header-home_link-icon {
		transform: scale(.71);
	}
}

.header_schedule_toggle {
	opacity: .8;
	
	transition: $defaultTransition;
	
	&:hover {
		opacity: 1;
	}
}

.header_schedule_toggle-icon {
	display: none;
}

@media only screen and (max-width: $iphone5Landscape) {
	.header-container {
		padding-left: gridUnit(1);
		padding-right: gridUnit(1);
	}
	
	.header-home_link-icon {
		width: pxToRem(18);
	}
	
	.header_user_profile-modal {
		left: 0;
		right: auto;
		
		width: 100%;
	}
	
	.header_schedule_toggle-icon {
		position: relative;
		top: pxToRem(2);
		display: inline-block;
		vertical-align: middle;
		width: pxToRem(14);
	}
	
	.header_schedule_toggle-text {
		display: none;
	}
	
	.header.header--small {
		.header-home_link-icon {
			transform: scale(1);
		}
	}
}
