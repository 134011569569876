////
/// Global elements
/// Must be first-level tag selector
////

html {
	font-family: $fontText;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-size: 100%;

	box-sizing: border-box;
}

body {
	background: $colorDarkGrey;
	color: $colorText;

	&.no-scroll--enabled {
		overflow: hidden;
	}
}

.main {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	min-height: 100vh;
	overflow: hidden;

	background-color: $backgroundSectionColor;
}

::selection {
	background: $colorSelection;
	color: #fff;
}
::-moz-selection {
	background: $colorSelection;
	color: #fff;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide:not(.ng-hide-animate) {
	display: none !important;
}

[ng-click],
[data-ng-click],
[x-ng-click] {
	cursor: pointer;
}
