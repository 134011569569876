.livestream_share {
	position: absolute;

	width: pxToRem(232);
	left: 0;
	bottom: pxToRem(72);
	padding: pxToRem(24) pxToRem(24) pxToRem(32);

	background-color: $colorDarkGrey;

	transform: translateY(10%) translateZ(0);

	visibility: hidden;
	opacity: 0;

	transition: $defaultTransition;
	&--active {
		transform: translateY(0) translateZ(0);

		opacity: 1;
		visibility: visible;

		// transition: opacity 200ms ease-in-out, visibility 0ms ease-in-out;
	}
}

.livestream_share-title {
	@include deco_typo_body_small_bold;

	margin-bottom: pxToRem(24);

	text-align: center;
	color: #fff;
}

.livestream_share-button {
	justify-content: flex-start;

	&:not(:last-child) {
		margin-bottom: pxToRem(8);
	}
	.icon {
		width: pxToRem(20);
		height: pxToRem(20);
	}
}

@media only screen and (max-width: $panelWidth) {
	.livestream_share {
		bottom: pxToRem(65);
		width: 100vw;
		
		transform: translate3d(#{pxToRem(-162)}, 10%, 0);
		
		&--active {
			transform: translate3d(#{pxToRem(-162)}, 0, 0);
		}
	}
}