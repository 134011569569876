@mixin deco_buttons_base {
	display: inline-block;
	position: relative;
	overflow: hidden;


	min-width: pxToRem(200);

	color: #fff;
	letter-spacing: pxToRem(0.6);
	font-weight: 900;
	font-size: pxToRem(14);
	text-decoration: none;
	text-align: center;

	background-color: transparent;
	outline: none;
	border: none;
	border-radius: pxToRem(100);

	z-index: 1;

	&:disabled {
		opacity: 0.3;
	}
}

@mixin deco_buttons_plain {
	background-color: $strongCyan;
	padding: pxToRem(13) pxToRem(13) pxToRem(12) pxToRem(13);

	&:before {
		content: "";

		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;

		background: $gradient_strongCyan_aquaGreen;
		border-radius: pxToRem(100);

		opacity: 0;
		z-index: -1;

		transition: $defaultTransition;
	}

	&:after {
		content: "";

		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;

		opacity: 0;

		border: pxToRem(6) solid rgba(255, 255, 255, 0.6);
		border-radius: pxToRem(100);

		transition: $defaultTransition;
	}

	&:not(:disabled):hover,
	&:not(:disabled):active {
		background-color: transparent;

		transition: all 0ms 300ms linear;
		&:before {
			opacity: 1;
		}
	}
	&:not(:disabled):active {
		&:after {
			opacity: 1;
		}
	}


}

@mixin deco_buttons_plain_secondary {
	background-color: $pink;

	&:before {
		background-image: $gradient_pink_purple;
	}
}

@mixin deco_buttons_neutral {
	padding: pxToRem(13) pxToRem(13) pxToRem(12) pxToRem(13);

	transition: $defaultTransition;

	&:before {
		content: "";

		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		border-radius: pxToRem(100);

		z-index: -1;
		opacity: 0;

		background-image: $gradient_purple_pink;

		transition: $defaultTransition;
	}

	&:after {
		content: "";

		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		border-radius: pxToRem(100);
		border: pxToRem(3) solid #fff;

		z-index: -1;

		transition: $defaultTransition;
	}

	&:not(:disabled):hover,
	&:not(:disabled):active {
		color: $pink;

		transition: $defaultTransition;

		&:after {
			border-width: 0;
			background-color: #fff;
		}
	}

	&:not(:disabled):active {
		&:after {
			left: 6px;
			top: 6px;
			width: calc(100% - 12px);
			height: calc(100% - 12px);

			background-color: rgba(255,255,255,0.8);
		}
		&:before {
			opacity: 1;

			transition: all 0ms linear;
		}
	}
}

@mixin deco_buttons_neutral_secondary {
	&:before {
		background-image: $gradient_strongCyan_aquaGreen;
	}

	&:not(:disabled):hover,
	&:not(:disabled):active {
		color: $strongCyan;
	}
}

@mixin deco_buttons_small {
	padding: pxToRem(12.5) pxToRem(20);
	min-width: pxToRem(176);
}

@mixin deco_buttons_vsmall {
	padding: pxToRem(8.5) pxToRem(12);
	min-width: pxToRem(168);
}

@mixin deco_buttons_inactive_info {
	display: inline-block;

	padding: pxToRem(6) pxToRem(15);

	font-size: pxToRem(10);
	font-weight: 900;
	color: #fff;
	letter-spacing: pxToRem(1);
	text-transform: uppercase;

	border-radius: pxToRem(100);
	background-image: $gradient_strongCyan_aquaGreen;
}
