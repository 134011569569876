////
/// Fonts declaration
////

@font-face {
	font-family: "MuseoSansRounded";
	src: url("../fonts/MuseoSansRounded-100/MuseoSansRounded-100.eot");
	src: url("../fonts/MuseoSansRounded-100/MuseoSansRounded-100.eot?#iefix") format("embedded-opentype"),
		 url("../fonts/MuseoSansRounded-100/MuseoSansRounded-100.woff") format("woff"),
		 url("../fonts/MuseoSansRounded-100/MuseoSansRounded-100.woff2") format("woff2"),
		 url("../fonts/MuseoSansRounded-100/MuseoSansRounded-100.ttf") format("truetype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "MuseoSansRounded";
	src: url("../fonts/MuseoSansRounded-300/MuseoSansRounded-300.eot");
	src: url("../fonts/MuseoSansRounded-300/MuseoSansRounded-300.eot?#iefix") format("embedded-opentype"),
		 url("../fonts/MuseoSansRounded-300/MuseoSansRounded-300.woff") format("woff"),
		 url("../fonts/MuseoSansRounded-300/MuseoSansRounded-300.woff2") format("woff2"),
		 url("../fonts/MuseoSansRounded-300/MuseoSansRounded-300.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "MuseoSansRounded";
	src: url("../fonts/MuseoSansRounded-500/MuseoSansRounded-500.eot");
	src: url("../fonts/MuseoSansRounded-500/MuseoSansRounded-500.eot?#iefix") format("embedded-opentype"),
		 url("../fonts/MuseoSansRounded-500/MuseoSansRounded-500.woff") format("woff"),
		 url("../fonts/MuseoSansRounded-500/MuseoSansRounded-500.woff2") format("woff2"),
		 url("../fonts/MuseoSansRounded-500/MuseoSansRounded-500.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "MuseoSansRounded";
	src: url("../fonts/MuseoSansRounded-900/MuseoSansRounded-900.eot");
	src: url("../fonts/MuseoSansRounded-900/MuseoSansRounded-900.eot?#iefix") format("embedded-opentype"),
		 url("../fonts/MuseoSansRounded-900/MuseoSansRounded-900.woff") format("woff"),
		 url("../fonts/MuseoSansRounded-900/MuseoSansRounded-900.woff2") format("woff2"),
		 url("../fonts/MuseoSansRounded-900/MuseoSansRounded-900.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
}
