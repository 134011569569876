@mixin deco_containers_fullWidth {
	margin: 0 auto;
	width: 100%;
	max-width: none;
	padding: 0 $gutterVeryLargeScreens;
}

@mixin deco_containers_vlarge {
	margin: 0 auto;
	width: 100%;
	max-width: $containerVeryLarge;
	padding: 0 $gutterVeryLargeScreens;
}

@mixin deco_containers_large {
	margin: 0 auto;
	width: 100%;
	max-width: $containerLarge;
	padding: 0 $gutterLargeScreens;
}
