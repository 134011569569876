@mixin deco_sections_default {
	position: relative;

	width: 100%;
	max-width: none;

	padding: 0;
	margin: 0;
}

@mixin deco_sections_padding_50 {
	position: relative;

	width: 100%;
	max-width: none;

	padding: pxToRem(50) 0;
	margin: 0;
}

@mixin deco_sections_padding_16 {
	position: relative;

	width: 100%;
	max-width: none;

	padding: gridUnit(1) 0;
	margin: 0;
}
