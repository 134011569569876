////
/// @group Helpers
////

/// Convert Px to REM unit
@function pxToRem($pixels) {
	@return #{$pixels/16}rem;
}

/// Convert Grid Unit to REM unit
@function gridUnit($grids) {
	@return pxToRem($grids * 16);
}
