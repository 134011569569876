.video_toggle {
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.video_toggle-inner {
	position: absolute;
	z-index: 1;
	left: 50%;
	top: 50%;
	
	width: pxToRem(50);
	height: pxToRem(50);
	margin-left: pxToRem(-25);
	margin-top: pxToRem(-50);
	
	border: pxToRem(2) solid;
	border-radius: 50%;
	
	color: white;
	
	opacity: 0;
	transform: translate3d(15vw, 5vw, 0);
	
	transition: $defaultTransition;
	
	&:before,
	&:after {
		content: '';
		
		position: relative;
		top: pxToRem(12);
		display: inline-block;
		vertical-align: middle;
		width: pxToRem(2);
		height: pxToRem(16);
		
		background: white;
		
		opacity: 0;
		
		transform: scale(0);
		
		transition: $defaultTransition;
	}
	
	&--pause {
		&:before,
		&:after {
			opacity: 1;
			
			transform: scale(1);
		}
	}
	
	&--moved {
		opacity: 1;
	}
}

.video_toggle-text {
	@include hidden-visually;
}

.video_toggle-play {
	position: absolute;
	left: 50%;
	top: 50%;
	
	width: 0;
	height: 0;
	margin-left: pxToRem(-4);
	margin-top: pxToRem(-8);
	
	border-style: solid;
	border-width: pxToRem(8) 0 pxToRem(8) pxToRem(12);
	border-color: transparent transparent transparent white;
	
	opacity: 1;
	transform: scale(1);
	
	transition: $defaultTransition;
	
	.video_toggle-inner--pause & {
		opacity: 0;
		transform: scale(0);
	}
}