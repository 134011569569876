.breadcrumb {
	margin-left: pxToRem(25);
}

.breadcrumb-list {
	display: flex;
}

.breadcrumb-item-link {
	@include deco_typo_body_small_bold;
	display: block;
	position: relative;

	padding: 0 pxToRem(20);

	text-decoration: none;
	text-transform: capitalize;
	color: #fff;
	
	.breadcrumb-item:last-child & {
		font-weight: 900;
	}

	&:before {
		content: "";

		position: absolute;
		width: 6px;
		height: 10px;
		left: 0;
		top: pxToRem(-1);
		bottom: 0;
		margin: auto;

		opacity: 0.5;

		background: url(../images/icons/arrow--white.svg) no-repeat center center transparent;
		background-size: 100%;
	}
}

@media only screen and (max-width: $ipadLandscape) {
	.breadcrumb {
		display: none;
	}
}
