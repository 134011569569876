.panel-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	background-color: rgba(0,0,0,0.7);

	opacity:0;
	cursor: default;
	z-index: -1;

	transition: opacity 200ms ease-in-out, z-index 0ms 200ms linear;
	&--active {
		opacity: 1;
		z-index: $zIndexModalOverlay;

		transition: opacity 200ms ease-in-out, z-index 0ms linear;
	}
}

.panel {
	position: fixed;
	overflow: auto;

	top: 0;

	width: 100%;
	max-width: pxToRem(480);
	height: 100vh;

	z-index: $zIndexModal;
	background-color: #fff;

	-webkit-overflow-scrolling: touch;

	transition: $defaultTransition;

	&--left {
		left: 0;
		box-shadow: pxToRem(3.5) 0 pxToRem(20) 0 rgba(0, 0, 0, 0);

		transform: translateX(-100%) translateZ(0);
		&.panel--active {
			box-shadow: pxToRem(3.5) 0 pxToRem(20) 0 rgba(0, 0, 0, 0.5);

			transform: translateX(0) translateZ(0);
		}
	}

	&--right {
		right: 0;
		box-shadow: pxToRem(-3.5) 0 pxToRem(20) 0 rgba(0, 0, 0, 0);

		transform: translateX(100%) translateZ(0);
		&.panel--active {
			box-shadow: pxToRem(-3.5) 0 pxToRem(20) 0 rgba(0, 0, 0, 0.5);

			transform: translateX(0) translateZ(0);
		}
	}


}
