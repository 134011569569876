.reminder_stripe {
	@include deco_sections_default;

	color: #fff;

	&:before {
		content: '';

		position: absolute;
		z-index: 1;
		left: pxToRem(-70);
		top: pxToRem(-60);
		display: block;
		width: pxToRem(236);
		height: pxToRem(219);
		
		background-image: url(../images/decorator_shapes/curve_4.svg);
		background-repeat: no-repeat;
		background-size: cover;
		
		animation: floating 10s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
	}
}

.reminder_stripe-container {
	background-image: $gradient_strongCyan_aquaGreen;
}

.reminder_stripe-content {
	@include deco_sections_padding_50
	@include deco_alignment_center_center;


	flex-direction: column;
}

.reminder_stripe-title {
	@include deco_typo_heading_3;
	font-weight: 900;
}

.reminder_stripe-description {
	margin: pxToRem(11) 0 0;

	text-align: center;
}

.reminder_stripe-btn {
	margin: pxToRem(28) 0 0;
}

@media only screen and (max-width: $iphone5Landscape) {
	.reminder_stripe {
		&:before {
			display: none;
		}
	}
}