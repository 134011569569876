input,
select {
	width: 100%;
}

label {
	position: relative;
	display: block;

	font-size: pxToRem(14);
	letter-spacing: pxToRem(0.4);
	text-align: left;

	.label--secondary_link {
		@include deco_typo_body_vsmall;
		position: absolute;
		top: 0;
		right: 0;

		padding: 0;
		margin: 0;

		color: $mediumGrey;
		text-decoration: underline;
	}
}

label input,
label select {
	margin-top: pxToRem(11);
}

input,
select {
	padding: 10.5px 8px;

	font-size: pxToRem(14);

	border: 1px solid $lightGrey;
	border-radius: 0;
	background-color: transparent;
	outline: none;

	transition: border-color 300ms ease-in-out, -webkit-autofill 0ms linear;
	&:focus {
		border-color: $strongCyan;
	}
	&:-webkit-autofill {
		-webkit-box-shadow: 0 0 0px 1000px white inset;
	}
}

select {
	padding-right: 40px;
	-webkit-appearance: none;

	background: url(../images/icons/arrow--grey.svg) no-repeat 88% 50% transparent;
}

.input-submit {
	@include deco_buttons_base;
	@include deco_buttons_plain;

	width: 100%;

	input[type=submit] {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		opacity: 0;
	}
}
